<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-row
      class="mt-3"
    >
      <b-col
        cols="12"
      >
        <b-card>
          <ValidationObserver
            ref="formChangePassword"
          >
            <form
              novalidate
              role="form"
              @submit.prevent
            >
              <b-row>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    vid="current_password"
                    name="current password"
                    rules="required"
                  >
                    <b-form-group
                      label="Current Password"
                      label-for="current_password"
                      label-class="font-weight-bold"
                      class="mb-2"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input
                          id="current_password"
                          v-model="credentials.current_password"
                          placeholder="enter current password"
                          autocomplete="off"
                          :type="currentPasswordType"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <b-input-group-append>
                          <b-button
                            class="pl-2 pr-1"
                            variant="success"
                            style="border-radius: 0px 5px 5px 0px"
                            :disabled="state.busy"
                            @click="toggleCurrentPassword"
                          >
                            <i
                              v-if="state.current_password_visible"
                              class="ri-eye-line h5"
                            />
                            <i
                              v-else
                              class="ri-eye-off-line h5"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    vid="password"
                    name="new password"
                    rules="required|min:8"
                  >
                    <b-form-group
                      label="New Password"
                      label-for="password"
                      label-class="font-weight-bold"
                      class="mb-2"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input
                          id="password"
                          v-model="credentials.password"
                          placeholder="enter new password"
                          data-vv-as="password-confirmation"
                          autocomplete="off"
                          :type="newPasswordType"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <b-input-group-append>
                          <b-button
                            class="pl-2 pr-1"
                            variant="success"
                            style="border-radius: 0px 5px 5px 0px"
                            :disabled="state.busy"
                            @click="toggleNewPassword"
                          >
                            <i
                              v-if="state.new_password_visible"
                              class="ri-eye-line h5"
                            />
                            <i
                              v-else
                              class="ri-eye-off-line h5"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="confirm password"
                    vid="password_confirmation"
                    rules="required|min:8|confirmed:password"
                  >
                    <b-form-group
                      label="Confirm New Password"
                      label-for="password_confirmation"
                      label-class="font-weight-bold"
                      class="mb-2"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input
                          id="password_confirmation"
                          v-model="credentials.password_confirmation"
                          placeholder="confirm new password"
                          data-vv-as="password-confirmation"
                          autocomplete="off"
                          :type="newPasswordType"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <b-input-group-append>
                          <b-button
                            class="pl-2 pr-1"
                            variant="success"
                            style="border-radius: 0px 5px 5px 0px"
                            :disabled="state.busy"
                            @click="toggleNewPassword"
                          >
                            <i
                              v-if="state.new_password_visible"
                              class="ri-eye-line h5"
                            />
                            <i
                              v-else
                              class="ri-eye-off-line h5"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>

              <div class="d-inline-block w-100">
                <b-button
                  variant="success"
                  :disabled="state.busy"
                  @click="onValidateChangePassword"
                >
                  Update Password
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SharedAuthService } from '@/services'
import misc from '@/mixins/misc'

export default {
  name: 'SharedChangePassword',

  middleware: ['auth'],

  mixins: [misc],

  metaInfo: () => ({
    title: 'Change Password'
  }),

  data () {
    return {
      state: {
        busy: false,
        current_password_visible: false,
        new_password_visible: false
      },
      credentials: {
        current_password: null,
        password: null,
        password_confirmation: null
      }
    }
  },

  computed: {

    currentPasswordType () {
      return this.state.current_password_visible ? 'text' : 'password'
    },

    newPasswordType () {
      return this.state.new_password_visible ? 'text' : 'password'
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    toggleCurrentPassword () {
      this.state.current_password_visible = !this.state.current_password_visible
    },

    toggleNewPassword () {
      this.state.new_password_visible = !this.state.new_password_visible
    },

    async onValidateChangePassword (event) {
      event.preventDefault()
      await this.$refs.formChangePassword.validate().then(
        async allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirm({
              html: '<h6>Update your Password?</h6>',
              preConfirm: () => {
                this.state.busy = true
                return this.onPostChangePassword()
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    async onPostChangePassword () {
      return new Promise(resolve => {
        SharedAuthService.ChangePassword(this.credentials).then(
          ({ data: { message } }) => {
            this.swalSuccess(message).then(
              () => {
                this.credentials.current_password = null
                this.credentials.password = null
                this.credentials.password_confirmation = null
                this.$refs.formChangePassword.reset()
              }
            )
          }
        ).catch(
          ({ message }) => {
            this.$refs.formChangePassword.setErrors(message)
            resolve(message)
          }
        ).finally(() => {
          this.state.busy = false
        })
      })
    }
  }

}
</script>
